// // ** Reducers Imports
import layout from '../layout'
import navbar from '../navbar'
import roleReducer from './RoleReducer'
import moduleReducer from './ModuleReducer'
import memberListingReducer from './MemberListingReducer'
import partnerReducer from './PartnerReducer'
import countryReducer from './countryReducer'
import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import SidebarReducer from './SidebarReducer'
import hardSet from 'redux-persist/lib/stateReconciler/hardSet'
import supportReducer from './SupportReducer'
import availabilityReducer from './AvailabilityReducer'
import eventReducer from './EventReducer'
import messageReducer from './MessageReducer'
import customerReducer from './CustomerReducer'
import venueReducer from './VenueReducer'
import notificationReducer from './NotificationReducer'
const persistConfig = {
    key: 'root',
    /*eslint-disable-next-line */
    storage: storage,
    stateReconciler: hardSet,
    // whitelist: ['navbar', 'layout', 'module']
    whitelist: ['sidebar', 'navbar', 'layout', 'module', 'moduleReducer']
}

const rootReducer = combineReducers({
    sidebar: SidebarReducer,
    navbar,
    layout,
    roleReducer,
    moduleReducer,
    memberListingReducer,
    partnerReducer,
    countryReducer,
    supportReducer,
    availabilityReducer,
    eventReducer,
    messageReducer,
    customerReducer,
    venueReducer,
    notificationReducer
})
export default persistReducer(persistConfig, rootReducer)
