import { EVENT_LIST, EVENT_TOTAL } from '../../constants/ReducerConstants'

// import from files
const initialState = {
    eventList: [],
    totalEvent: 0
}
// reducer for shop listing
const eventReducer = (state = initialState, action) => {
    switch (action.type) {
        case EVENT_LIST:
            return { ...state, eventList: action.payload }
        case EVENT_TOTAL:
            return { ...state, totalEvent: action.payload }
        default: {
            return state
        }
    }
}
export default eventReducer
