import { CLIENT_SUPPORT_LIST, CLIENT_SUPPORT_TOTAL, PARTNER_SUPPORT_LIST, PARTNER_SUPPORT_TOTAL } from '../../constants/ReducerConstants'

// import from files
const initialState = {
    partnerTicket: [],
    totalPartnerTicket: 0,
    clientTicket: [],
    totalClientTicket: 0
}
// reducer for shop listing
const supportReducer = (state = initialState, action) => {
    switch (action.type) {
        case PARTNER_SUPPORT_LIST:
            return { ...state, partnerTicket: action.payload }
        case PARTNER_SUPPORT_TOTAL:
            return { ...state, totalPartnerTicket: action.payload }
        case CLIENT_SUPPORT_LIST:
            return { ...state, clientTicket: action.payload }
        case CLIENT_SUPPORT_TOTAL:
            return { ...state, totalClientTicket: action.payload }
        default: {
            return state
        }
    }
}
export default supportReducer
