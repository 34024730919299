import { ROLE_LIST, ROLE_TOTAL } from '../../constants/ReducerConstants'

const initialState = {
    role: [],
    totalRole: 0
}

const roleReducer = (state = initialState, action) => {
    switch (action.type) {
        case ROLE_LIST:
            return { ...state, role: action.payload }
        case ROLE_TOTAL:
            return { ...state, totalRole: action.payload }
        default: {
            return state
        }
    }
}

export default roleReducer
