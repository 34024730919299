import { MESSAGE_LIST, MESSAGE_TOTAL } from '../../constants/ReducerConstants'

// import from files
const initialState = {
    messsageList: [],
    totalMessage: 0
}
// reducer for shop listing
const messageReducer = (state = initialState, action) => {
    switch (action.type) {
        case MESSAGE_LIST:
            return { ...state, messsageList: action.payload }
        case MESSAGE_TOTAL:
            return { ...state, totalMessage: action.payload }
        default: {
            return state
        }
    }
}
export default messageReducer
