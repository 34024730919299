import { PARTNER_LIST, PARTNER_TOTAL } from '../../constants/ReducerConstants'

// import from files
const initialState = {
    partner: [],
    totalPartner: 0
}
// reducer for shop listing
const partnerReducer = (state = initialState, action) => {
    switch (action.type) {
        case PARTNER_LIST:
            return { ...state, partner: action.payload }
        case PARTNER_TOTAL:
            return { ...state, totalPartner: action.payload }
        default: {
            return state
        }
    }
}
export default partnerReducer
