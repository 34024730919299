import { CUSTOMER_LIST, CUSTOMER_TOTAL } from '../../constants/ReducerConstants'
const initialState = {
    customer: [],
    totalCustomer: 0
}

const customerReducer = (state = initialState, action) => {
    switch (action.type) {
        case CUSTOMER_LIST:
            return { ...state, customer: action.payload }
        case CUSTOMER_TOTAL:
            return { ...state, totalCustomer: action.payload }
        default: {
            return state
        }
    }
}
export default customerReducer
