export const ROLE_LIST = 'ROLE_LIST'
export const ROLE_TOTAL = 'ROLE_TOTAL'
export const MODULE_LIST = 'MODULE_LIST'
export const MODULE_TOTAL = 'MODULE_TOTAL'
export const PARTNER_LIST = 'PARTNER_LIST'
export const PARTNER_TOTAL = 'PARTNER_TOTAL'
export const COUNTRY_LIST = 'COUNTRY_LIST'
export const COUNTRY_TOTAL = 'COUNTRY_TOTAL'
export const SIDEBAR_LIST = 'SIDEBAR_LIST'
export const SIDEBAR_TOTAL = 'SIDEBAR_TOTAL'
export const MODULE_ACCESS_LIST = 'MODULE_ACCESS_LIST'
export const MODULE_ACCESS_TOTAL = 'MODULE_ACCESS_TOTAL'
export const VIDEO_LIST = 'VIDEO_LIST'
export const VIDEO_TOTAL = 'VIDEO_TOTAL'
export const RESOURCE_LIST = 'RESOURCE_LIST'
export const RESOURCE_TOTAL = 'RESOURCE_TOTAL'
export const PARTNER_SUPPORT_LIST = 'PARTNER_SUPPORT_LIST'
export const PARTNER_SUPPORT_TOTAL = 'PARTNER_SUPPORT_TOTAL'
export const CLIENT_SUPPORT_LIST = 'CLIENT_SUPPORT_LIST'
export const CLIENT_SUPPORT_TOTAL = 'CLIENT_SUPPORT_TOTAL'
export const AVAILABILITY_LIST = 'AVAILABILITY_LIST'
export const AVAILABILITY_TOTAL = 'AVAILABILITY_TOTAL'
export const AVAILABILITY_DETAIL_LIST = 'AVAILABILITY_DETAIL_LIST'
export const AVAILABILITY_DETAIL_TOTAL = 'AVAILABILITY_DETAIL_TOTAL'
export const MESSAGE_LIST = 'MESSAGE_LIST'
export const MESSAGE_TOTAL = 'MESSAGE_TOTAL'
export const EVENT_LIST = 'EVENT_LIST'
export const EVENT_TOTAL = 'EVENT_TOTAL'
export const LOG_OUT = 'LOG_OUT'
export const CUSTOMER_LIST = 'CUSTOMER_LIST'
export const CUSTOMER_TOTAL = 'CUSTOMER_TOTAL'
export const VENUE_LIST = 'VENUE_LIST'
export const VENUE_TOTAL = 'VENUE_TOTAL'
export const NOTIFICATION_LIST = 'NOTIFICATION_LIST'
export const NOTIFICATION_TOTAL = 'NOTIFICATION_TOTAL'
export const RECIPIENT_LIST = 'RECIPIENT_LIST'
export const RECIPIENT_TOTAL = 'RECIPIENT_TOTAL'
